import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import { NavigationService } from 'src/app/shared/services/navigation.service';
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['../connexion.scss']
})
export class PageLoginComponent {
  loginForm: FormGroup;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              protected nav: NavigationService,
              private snack: MatSnackBar) {
    this.loginForm  = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    })
  }


  submit() {
    if(this.loginForm.invalid) {
      this.snack.open('Le formulaire est faux veuillez verifier les informations entrées')
      return
    }
    this.userService.login(this.loginForm.value)
  }


}
