import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {credentials} from "../../../../../fe-global-components/interfaces/api_forms";


@Injectable({
  providedIn: 'root'
})
export class BffApiService {
  headers = {'Accept': 'application/json'};
  options = { headers: this.headers, withCredentials: true }
  url = environment.apiUrl + environment.apiVersion
  constructor(private http: HttpClient) { }

  getToken(): Observable<any> {
    /**
     * Le cookie se met automatiquement dans le header de la requête.
     */
    const url = `${environment.apiUrl}/sanctum/csrf-cookie`;
    return this.http.get(url, this.options);
  }

  /** customer */
  register(credential: credentials): Observable<any> {
    const url = `${this.url}/customer/register`;
    return this.http.post( url, credential, this.options);
  } // register

  login(credential: credentials): Observable<any> {
    const url = `${this.url}/customer/login`;
    return this.http.post(url, credential,this.options);
  } // login

  logout(): Observable<any> {
    const url = `${this.url}/customer/logout`;
    return this.http.post(url, '',this.options);
  } // logout

  forgotten_password(email: string): Observable<any> {
    const url = `${this.url}/customer/forgot-password`;
    return this.http.post(url, email ,this.options);
  }

  updatePassword(body: any): Observable<any> {
    /**
     * When forgotten password
     */
    const url = `${this.url}/common/password`;
    return this.http.put(url, body,this.options);
  }

  resetPassword(body: any): Observable<any> {
    /**
     * When logged in and want to update pwd
     */
    const url = `${this.url}/customer/reset-password`;
    return this.http.post(url, body,this.options);
  }

  deleteAccount(): Observable<any> {
    const url = `${this.url}/customer/delete-account`;
    return this.http.post(url, this.options);
  }

  verify_hash(password: string): Observable<any> {
    const url = `${this.url}/customer/reset-password`;
    return this.http.post(url, '',this.options);
  }
  verification_notification(password: string): Observable<any> {
    const url = `${this.url}/customer/reset-password`;
    return this.http.post(url, '',this.options);
  }
  putProfilInfos(password: string): Observable<any> {
    const url = `${this.url}/customer/profile-information`;
    return this.http.put(url, '',this.options);
  }

  getProfilInfos(): Observable<any> {
    const url = `${this.url}/customer/profile-information`;
    return this.http.get(url, this.options);
  }


  /** company interface*/
  company_stock(password: string): Observable<any> {
    const url = `${this.url}/company/stock`;
    return this.http.get(url, this.options);
  }


  apiUrl: string =  'https://api-vestra-staging.exe-cute.ch/api/v1/public/';

  register_newsletter(form: any): Observable<any> {
    const url = `${this.url}/public/newsletter?firstname=${form.firstName}&lastname=${form.lastName}&company=${form.companyName}&email_address=${form.email}&state=${form.state}`;
    return this.http.post(url, '' ,this.options);
  } // login

  getUserRole(): Observable<any> {
    // TODO nicolas
    const url = `${this.url}/user-role`;
    return this.http.get(url, this.options);
  }

  sendVerificationEmail(): Observable<any> {
    const url = `${this.url}/customer/email/verification-notification`
    return this.http.post(url,'' ,this.options)
  }


  /**
   * Shop related
   */
  getColors(): Observable<any> {
    const url= `${this.url}/shop/colors`;
    return this.http.get(url, this.options);
  }

  getSizes(): Observable<any> {
    const url = `${this.url}/shop/sizes`;
    return this.http.get(url, this.options);
  }

  getProducts(param?: string): Observable<any> {
    const url = param
      ? `${this.url}/shop/articles/?filters${param}`
      : `${this.url}/shop/articles`;
    return this.http.get(url, this.options);
  }

  postBasket(article:number, variant: number, quantity=1):  Observable<any> {
    const url = `${this.url}/shop/basket/${article}/${variant}` // /${quantity}`
    return this.http.post(url,'' ,this.options)
  }

  delBasket(param?: string):  Observable<any> {
    const url = param
      ? `${this.url}/shop/basket/${param}`
      : `${this.url}/shop/basket/`;
    return this.http.delete(url, this.options)
  }

  getBasket(): Observable<any> {
    const url = `${this.url}/shop/basket`;
    return this.http.get(url, this.options);
  }

  getBasketPay(): Observable<any> {
    const url = `${this.url}/shop/basket/pay`;
    return this.http.get(url, this.options);
  }





}
