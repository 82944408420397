import { Component } from '@angular/core';
import { NavigationService } from "../../shared/services/navigation.service";
import { QuickViewComponent } from "../../component/article-component/quick-view/quick-view.component";
import { MatDialog } from "@angular/material/dialog";
import { Product } from "../../../../../fe-global-components/interfaces/interfaces";
import { products } from "../../../../../fe-global-components/mockup_data/mockup_data";
import { BffApiService } from "../../shared/api/bff-api.service";
import { ShopMenuService } from "../../shared/services/shop-menu.service";

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent {
  products: Product[] = products;

  nbrArticleEnLigne: number = 135; // TODO

  constructor(
    protected nav: NavigationService,
    private shopMenu: ShopMenuService,
    private dialog: MatDialog) {
    this.shopMenu.currentProducts.subscribe(products => this.products = products)
  }

  openDialog(product: Product): void {
    this.dialog
      .open(QuickViewComponent, { data: product, })
      .afterClosed()
      .subscribe(result => { });
  }


}



