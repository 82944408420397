// https://support.panierdequartier.fr/article/10-gerer-une-commande
export enum sale_status {
    WAITING_MONEY = 'Attente paiement',
    PAID = 'Payé',
    PENDING = 'En attente d\'expedition',
    SENT = 'Expédié',
    RETRIEVED = 'Réception confirmé',
    RETURNED = 'Renvoyé',
    REFUNDED = 'Remboursé',
    CLOSED = 'Clos',
}

export enum color {
    Grey = 'grey',
    Blue = 'blue',
    Red = 'red',
    Orange = 'orange',
    Green = 'green'
}