import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PageHomeComponent} from "./page/page-home/page-home.component";
import {PageLoginComponent} from "./page/connexion/page-login/page-login.component";
import {PageRegisterComponent} from "./page/connexion/page-register/page-register.component";
import {PageSearchArticleComponent} from "./page/page-search-article/page-search-article.component";
import {PageLogoutComponent} from "./page/connexion/page-logout/page-logout.component";
import {PageOneArticleComponent} from "./component/article-component/page-one-article/page-one-article.component";
import {CheckoutFormComponent} from "./page/checkout-journey/checkout-form/checkout-form.component";
import {PageResetPasswordComponent} from "./page/connexion/page-reset-password/page-reset-password.component";
import {SuccessPaymentComponent} from "./page/checkout-journey/success-payment/success-payment.component";
import {OverviewOrdersComponent} from "./page/checkout-journey/overview-orders/overview-orders.component";
import {FullOrderDetailsComponent} from "./page/checkout-journey/full-order-details/full-order-details.component";
import {PagePartenaireComponent} from "./page/page-partenaire/page-partenaire.component";
import {PageAproposComponent} from "./page/page-apropos/page-apropos.component";
import {PageApiComponent} from "./page/page-api/page-api.component";
import {PageCommerceComponent} from "./page/page-support-commerce/page-commerce.component";
import {UserDashboardComponent} from "./page/user-related/user-dashboard/user-dashboard.component";
import {UserProfilComponent} from "./page/user-related/user-dashboard-childs/user-profil/user-profil.component";
import {Role} from "../../../fe-global-components/interfaces/enums";
import {Page404Component} from "./page/page404/page404.component";
import {CheckEmailComponent} from "./page/connexion/check-email/check-email.component";
import {EmailVerifiedComponent} from "./page/connexion/email-verified/email-verified.component";
import {UserSecurityComponent} from "./page/user-related/user-dashboard-childs/user-security/user-security.component";
import {UserDeleteAccountComponent} from "./page/connexion/user-delete-account/user-delete-account.component";
import {CartPageComponent} from "./page/checkout-journey/cart-page/cart-page.component";
import {authGuard} from "./shared/services/auth.guard";

const routes: Routes = [
  {
    path: '',
    component: PageHomeComponent,
    data: {animation: 'Home'}
  },
  // connexion
  {
    path: 'login',
    component: PageLoginComponent,
  },
  {
    path: 'register',
    component: PageRegisterComponent,
  },
  {
    path: 'logout',
    component: PageLogoutComponent
  },
  {
    path: 'forgot-password',
    component: PageResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: PageResetPasswordComponent
  },
  {
    path: 'verify-email-address',
    component: EmailVerifiedComponent
  },
  {
    path: 'check-email/:email',
    component: CheckEmailComponent
  },
  // buyers
  {
    path: 'shop',
    component: PageSearchArticleComponent,
  },
  // {
  //   path: 'shop/',
  //   redirectTo: 'shop/:pageName'
  // },
  {
    path: 'shop/:pageName',
    component: PageSearchArticleComponent,
  },
  {
    path: 'shop/:category/:pageName',
    component: PageSearchArticleComponent,
  },
  {
    path: 'shop/:category/:subcategory/:pageName',
    component: PageSearchArticleComponent,
  },
  {
    path: 'shop/:category/:subcategory/:subsubcategory/:pageName',
    component: PageSearchArticleComponent,
  },
  {
    path: 'article/:id',
    component: PageOneArticleComponent,
  },
  {
    path: 'cart',
    component: CartPageComponent,
  },
  {
    path: 'checkout',
    component: CheckoutFormComponent,
  },
  {
    path: 'summary',
    component: SuccessPaymentComponent,
  },
  {
    path: 'orders-list',
    component: OverviewOrdersComponent,
  },
  {
    path: 'order-detail/:id',
    component: FullOrderDetailsComponent,
  },
  {
    path: 'partenaires',
    component: PagePartenaireComponent,
  },
  {
    path: 'a-propos',
    component: PageAproposComponent,
  },
  {
    path: 'support-commerce',
    component: PageCommerceComponent,
  },
  {
    path: 'api',
    component: PageApiComponent
  },
  {
    path: 'user',
    component: UserDashboardComponent,
    canActivate: [authGuard],     // TODO
    data: {
      roles: [ Role.USER ]
    },
    children: [
      {
        path: 'security',
        component: UserSecurityComponent,
        canActivate: [authGuard],
        data: {
          roles: [ Role.USER ]
        },
      },
      {
        path: 'delete-account',
        component: UserDeleteAccountComponent,
        canActivate: [authGuard],
        data: {
          roles: [ Role.USER ]
        },
      },
      // {
      //   path: 'profile',
      //   component: UserProfilComponent,
      //   canActivate: [authGuard],
      //   data: {
      //     roles: [ Role.USER ]
      //   },
      // },
      {
        path: 'orders',
        component: OverviewOrdersComponent,
        canActivate: [authGuard],
        data: {
          roles: [ Role.USER ]
        },
      },
      {
        path: 'order-details/:id',
        component: FullOrderDetailsComponent,
        canActivate: [authGuard],
        data: {
          roles: [ Role.USER ]
        },
      },
    ]
  },
  {
    path: '404-oups',
    component: Page404Component,
  },
  {
    path: '**', redirectTo: '404-oups'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

