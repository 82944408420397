import { Component, OnInit } from '@angular/core';
import { CartService } from "../../../shared/services/cart.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationService } from "../../../shared/services/navigation.service";
import { Color, Product, Size } from "../../../../../../fe-global-components/interfaces/interfaces";
import { colors, product, sizes, colorsDic, sizesDic, S, fabric } from "../../../../../../fe-global-components/mockup_data/mockup_data";
import { BffApiService } from "../../../shared/api/bff-api.service";
import { ActivatedRoute } from "@angular/router";
import { ProductsService } from 'src/app/shared/services/products.service';

@Component({
  selector: 'app-page-one-article',
  templateUrl: './page-one-article.component.html',
  styleUrls: ['./page-one-article.component.scss']
})
export class PageOneArticleComponent implements OnInit {
  article_form: FormGroup = this.fb.group({
    size: [null, Validators.required],
    color: [null, Validators.required],
  })

  product: Product = product;

  // TODO refactor with app-quick-view
  colors: Color[] = colors;
  sizes: Size[] = sizes;

  currentColor: Color | null = null;
  currentSize: Size | null = null;
  // TODO END refactor with app-quick-view

  constructor(protected cartService: CartService,
    private route: ActivatedRoute,
    private bff: BffApiService,
    private fb: FormBuilder,
    public nav: NavigationService,
    protected ps: ProductsService) { }

  ngOnInit(): void {
    let productID = this.route.snapshot.paramMap.get('id')
    if (productID) {
      this.bff.getProducts('[id][$eq]=' + productID).subscribe(res => {
        if (res['data'].length != 1) {
          //TODO throw error
          return
        }
        this.ps.initProduct(res['data'][0])
        this.ps.currentProduct.subscribe(p => this.product = p)

        this.ps._currentColors.subscribe(p => this.colors = p)
        this.ps._currentSize.subscribe(p => this.sizes = p)
        this.ps.curPickedColor.subscribe(p => this.currentColor = p)
        this.ps.curPickedSize.subscribe(p => this.currentSize = p)

        this.initForm()
      })
    }
    else {
      this.initForm()
    }
  }

  initForm() {
    this.article_form = this.fb.group({
      size: [this.colors[0].name, Validators.required],
      color: [this.sizes[1].name, Validators.required],
    })
  }


}
