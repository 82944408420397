import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Article, Basket, Order, Product } from "../../../../../fe-global-components/interfaces/interfaces";
import { emptyCart } from "../../../../../fe-global-components/constants/cart";
import { FormGroup } from "@angular/forms";
import { BffApiService } from "../api/bff-api.service";
import { SnackBarService } from "./snack/snack-bar.service";
import { ProductsService } from './products.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  order: Order | null = null;
  private cartSource: BehaviorSubject<Basket> = new BehaviorSubject(emptyCart);
  currentCart: Observable<Basket> = this.cartSource.asObservable();

  constructor(private snack: SnackBarService,
    private ps: ProductsService,
    private bffapi: BffApiService) {
    const cart: Basket = emptyCart;
    this.bffapi.getBasket().subscribe(result => {
      if (result.length == 0) {
        cart.articles
        cart.total = cart.articles.reduce((sum, article) => sum + (article.price * article.quantity), 0);
      }
      else {
        cart.articles = result
        cart.total = 0
        result.forEach((article: any) => {
          cart.total! += (article.price * + article.quantity)
        });
      }
      this.cartSource = new BehaviorSubject(cart)
      this.currentCart = this.cartSource.asObservable();
    })
  }

  // private saveCartToLocalStorage(cart:any) {
  //   // Save cart data to localStorage
  //   localStorage.setItem('cart', JSON.stringify(cart));
  // }

  addArticle(article_form: FormGroup, product: Product) {
    if (article_form.invalid) {
      this.snack.snack('sélectionner la taille et la couleur');
      return
    }

    let variante = product.variants.filter(v =>
      v.color.name === String(article_form.value.color) &&
      v.size.value === String(article_form.value.size)
    );

    console.log(variante)

    if (variante.length != 1) {
      this.snack.snack('sélectionner la taille et la couleur');
      return
    }

    let article: Article = {
      // ...product,
      brand: product.brand,
      description: product.description,
      name: product.name,
      old_price: product.old_price,
      price: product.price,
      parentId: product.id || -1,
      categories: product.categories,
      color: article_form.value.color,
      size: article_form.value.size,
      quantity: 1,
      id: variante[0].id || -1
    };

    const cart = this.cartSource.value
    this.bffapi.postBasket(product.id ? product.id : 0, variante[0].id ? variante[0].id : 0, 1).subscribe({
      next: res => {
        const index = cart.articles.findIndex((a) => a === article);

        if (index > -1) {
          cart.articles[index].quantity += 1;
        } else {
          cart.articles.push({ ...article, quantity: 1 });
        }
        cart.total = (cart.total || 0) + article.price;

        this.cartSource.next(cart)
        // this.saveCartToLocalStorage(cart)
        this.snack.snack('Article ajouté');
      },
      error: err => {
        this.snack.snack('Erreur, réeesayer plus tard');
      }
    })
  }

  removeArticle(article: any) {
    let cart = this.cartSource.value
    const index = cart.articles.indexOf(article);

     if (index > -1) {
      this.bffapi.delBasket(article.id + '/' + article.variant_id).subscribe(res => {
        cart.total! -= article.price * (article.quantity || 1);
        cart.articles.splice(index, 1);
        this.cartSource.next(cart);
        this.snack.snack('Article retiré');
      })
     }
  }

  checkout(checkoutForm: FormGroup) {
    this.bffapi.getBasketPay().subscribe(data => {
      this.snack.snack('Commande réservé !');
      this.cartSource.next(emptyCart)
    })
  }

  changeQuantity(article: any, newQuantity: number) {// TODO avoid any
    console.log(newQuantity)
    console.log(article)
    const cart = this.cartSource.value
    const index = cart.articles.indexOf(article);
    if (index !== -1) {
      this.bffapi.postBasket(article.parentId, article.id, newQuantity).subscribe(data => {
        let oldPrice = cart.articles[index].quantity * cart.articles[index].price
        cart.articles[index].quantity = newQuantity;
        cart.total! += (article.price * +newQuantity) - oldPrice;

        this.snack.snack('Article mis à jour');
      })
    }
    else {
      // TODO throw error.
    }
    // Save the updated cart to localStorage
    //this.saveCartToLocalStorage();
  }





}
