import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { colors, product, sizes, colorsDic, sizesDic } from '../../../../../fe-global-components/mockup_data/mockup_data';
import { Product, Color, Size } from "../../../../../fe-global-components/interfaces/interfaces";
import { BffApiService } from "../api/bff-api.service";
import { NavigationService } from "./navigation.service";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private productSource: BehaviorSubject<Product> = new BehaviorSubject(product);
  currentProduct: Observable<Product> = this.productSource.asObservable();

  private colorsSource: BehaviorSubject<Color[]> = new BehaviorSubject(colors);
  _currentColors: Observable<Color[]> = this.colorsSource.asObservable();

  private sizesSource: BehaviorSubject<Size[]> = new BehaviorSubject(sizes);
  _currentSize: Observable<Size[]> = this.sizesSource.asObservable();

  private pickedColorSrc: BehaviorSubject<Color | null> = new BehaviorSubject<Color | null>(null);
  curPickedColor: Observable<Color | null> = this.pickedColorSrc.asObservable();

  private pickedSizeSrc: BehaviorSubject<Size | null> = new BehaviorSubject<Size | null>(null);
  curPickedSize: Observable<Size | null> = this.pickedSizeSrc.asObservable();

  matchMatrix: any[][] = []

  constructor(
    private router: Router,
    public nav: NavigationService,
    private bff: BffApiService
  ) { }

  initProduct(product: Product) {
    this.productSource.next(product)
    this.getUniqueSizes();
    this.getUniqueColors();
  }

  getUniqueColors() { // TODO 
    let uniqueColors: Color[] = [];
    let productVariants = this.productSource.value.variants

    productVariants.forEach(v => {
      // Check if an element with the same size already exists
      if (!uniqueColors.some(color => color.name === v.color.name)) {
        uniqueColors.push(v.color);
      }
    });
    if (uniqueColors.length > 0) {
      let colors: Color[] = []
      uniqueColors.forEach(c => colors.push(colorsDic[c.name as keyof typeof colorsDic]))
      this.colorsSource.next(colors)
    }
  }

  getUniqueSizes() {
    const uniqueSizes: Size[] = [];
    let productVariants = this.productSource.value.variants
    productVariants.forEach(v => {
      // Check if an element with the same size already exists
      if (!uniqueSizes.some(size => size.name === v.size.name)) {
        if (!v.size.hasOwnProperty('available')) {
          v.size.available = true;
        }
        uniqueSizes.push(v.size);
      }
    });
    if (uniqueSizes.length > 0) {
      uniqueSizes.forEach(c => { sizes.push(sizesDic[c.name as keyof typeof sizesDic]) })
      this.sizesSource.next(uniqueSizes)
    }
  }


  getProduct(): Product {
    return this.productSource.value
  }


  reloadSize(color: any): void {
    let availableSizes = this.sizesSource.value;
    let productVariants = this.productSource.value.variants


    const lowerCaseColorName = color.name.toLocaleLowerCase();
    const availableSizeNames = new Set(
      productVariants
        .filter(v => v.color.name.toLocaleLowerCase() === lowerCaseColorName)
        .map(v => v.size.name)
    );

    availableSizes.forEach(e => {
      e.available = availableSizeNames.has(e.name);
    });

    this.sizesSource.next(availableSizes);
  }

  reloadColor(size: any): void {
    let availableColors = this.colorsSource.value;
    let productVariants = this.productSource.value.variants


    const lowerCaseSizeName = size.name.toLocaleLowerCase();
    const availableColorNames = new Set(
      productVariants
        .filter(v => v.size.name.toLocaleLowerCase() === lowerCaseSizeName)
        .map(v => v.color.name)
    );

    availableColors.forEach(e => {
      e.available = availableColorNames.has(e.name);
    });
  }

  selectColor(color: Color | null) {
    if (color && color.available) {
      this.pickedColorSrc.next(color);
      this.reloadSize(color)
    }
  }

  selectSize(size: Size | null) {
    if (size && size.available) {
      this.pickedSizeSrc.next(size)
      this.reloadColor(size)
    }
  }


  resetColor() {
    this.pickedColorSrc.next(null)
    let colors = this.colorsSource.value
    colors.forEach(c => { c.available = true })
    this.colorsSource.next(colors)
  }


  resetSize() {
    this.pickedSizeSrc.next(null)
    let sizes = this.sizesSource.value
    sizes.forEach(s => { s.available = true })
    this.sizesSource.next(sizes)
  }

}
