import { Component } from '@angular/core';
import {NavigationService} from "../../shared/services/navigation.service";
import {BffApiService} from "../../shared/api/bff-api.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {UserService} from "../../shared/services/user.service";
import {User} from "../../../../../fe-global-components/interfaces/interfaces";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss',
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)'}),
        animate('100ms ease-out', style({  opacity: 1 , transform: 'scale(1)'}))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('100ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
  ]
})
export class UserAvatarComponent {
  isMenu = false;
  is_logged: boolean = false;
  user: User = {first_name: '', last_name: ''};
  constructor(protected nav: NavigationService,
              private userService: UserService) {
    this.userService.currentUser.subscribe(user => { this.user = user})
  }
  toggleMenu(){
    this.isMenu = !this.isMenu;
  }

  goToLogOut() {
    this.toggleMenu()
    this.userService.logout()
  }

  goToLogin() {
    this.toggleMenu()
    this.nav.goToLogin()
  }

  goToMyOrders() {
    this.toggleMenu()
    this.nav.goToMyOrders()
  }

  goToProfile() {
    this.toggleMenu()
    this.nav.goToMyProfileSettings()
  }


}
