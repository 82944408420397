<div class="bg-white mt-16">
  <div *ngIf='isOffCanvasMenuDialog' class="relative z-40 lg:hidden" role="dialog" aria-modal="true">

    <div @opacityLinear *ngIf='isOffCanvasMenu' class="fixed inset-0 bg-black bg-opacity-25"></div>
    <div @translateX *ngIf='isOffCanvasMenu' class="fixed inset-0 z-40 flex pointer-events-auto">
      <div (click)="toggleOffCanvasMenu()" class="fixed inset-0"></div>

      <div @translateX *ngIf='isOffCanvasMenu' class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
        <div class="flex px-4 pt-5 pb-2">
          <button type="button" (click)="toggleOffCanvasMenu()" class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Links -->
        <div class="mt-2">
          <div class="border-b border-gray-200">
            <div class="overflow-x-auto -mb-px flex space-x-8 px-4" aria-orientation="horizontal" role="tablist">
              <!-- Selected: "border-stone-600 text-stone-600", Not Selected: "border-transparent text-gray-900" -->
              <button id="tabs-1-tab-{{i}}" *ngFor="let section of clothingSections; index as i"
                      [ngClass]="{
                  'text-stone-600': currentTab?.name == section.name,
                  'border-stone-600': currentTab?.name == section.name,
                  'text-gray-900': currentTab?.name != section.name,
                  'border-transparent': currentTab?.name != section.name
                }"
                      (click)="selectTab(section.name)"
                      class="border-transparent text-gray-900 flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium" aria-controls="tabs-1-panel-1" role="tab" type="button">
                {{ section.name }}
              </button>

            </div>
          </div>

          <!-- Tab panel, show/hide based on tab state. -->
          <div id="tabs-1-panel-1" class="space-y-10 px-4 pt-10 pb-8" aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
            <div class="space-y-4">
              <div *ngFor="let category of currentTab?.categories" class="group aspect-w-1 aspect-h-1 relative overflow-hidden rounded-md bg-gray-100">
                <img [src]="category.imgUrl" alt="{{category.altText}}" class="object-cover object-center group-hover:opacity-75">
                <div class="flex flex-col justify-end">
                  <div class="bg-white bg-opacity-60 p-4 text-base sm:text-sm">
                    <a [href]="category.href" class="font-medium text-gray-900">
                      <span class="absolute inset-0" aria-hidden="true"></span>
                      {{ category.name }}
                    </a>
<!--                    <p aria-hidden="true" class="mt-0.5 text-gray-700 sm:mt-1">Shop now</p>-->
                  </div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="currentTab?.links">
              <ng-container *ngFor="let quicklinks of currentTab?.links;  let i=index">
                <div class="space-y-10" *ngIf="i%2 == 1">
                  <div>
                    <p id="women-shoes-heading-mobile1" class="font-medium text-gray-900">{{quicklinks.name}}</p>
                    <ul role="list" aria-labelledby="women-shoes-heading-mobile" class="mt-6 flex flex-col space-y-6">
                      <li *ngFor="let links of quicklinks.links" class="flow-root">
                        <a (click)="this.gotToCategory(currentTab!, quicklinks!, links!);
                        toggleOffCanvasMenu();
                        nav.preventDefault($event);" 
                        class="-m-2 block p-2 text-gray-500 cursor-pointer">{{ links.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="i%2 == 0">
                  <p id="women-shoes-heading-mobile2" class="font-medium text-gray-900">{{quicklinks.name}}</p>
                  <ul role="list" aria-labelledby="women-shoes-heading-mobile" class="mt-6 flex flex-col space-y-6">
                    <li *ngFor="let links of quicklinks.links" class="flow-root">
                      <a (click)="this.gotToCategory(currentTab!, quicklinks!, links!);
                      toggleOffCanvasMenu();
                      nav.preventDefault($event);" 
                      class="-m-2 block p-2 text-gray-500 cursor-pointer">{{ links.name }}</a>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <header class="relative bg-white z-10">
    <nav aria-label="Top" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="border-b border-gray-200">
        <div class="flex h-16 items-center justify-between">
          <div class="flex flex-1 items-center lg:hidden">
            <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
            <button type="button" (click)="toggleOffCanvasMenu()" class="-ml-2 rounded-md bg-white p-2 text-gray-400">
              <span class="sr-only">Open menu</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>

            <!--            <a href="#" class="ml-2 p-2 text-gray-400 hover:text-gray-500">-->
            <!--              <span class="sr-only">Search</span>-->
            <!--              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
            <!--                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />-->
            <!--              </svg>-->
            <!--            </a>-->
          </div>

          <!-- Flyout menus (PC)-->
          <div class="hidden lg:block lg:flex-1 lg:self-stretch">
            <div class="flex h-full space-x-8">
              <div *ngFor="let section of clothingSections" class="flex">
                <div class="relative flex">
                  <!-- Item active: "text-stone-600", Item inactive: "text-gray-700 hover:text-gray-800" -->
                  <button type="button" (click)="selectCategory(section.name)"
                          [ngClass]="{
                      'text-stone-600': currentMenu?.name == section.name,
                      'text-gray-700': currentMenu?.name != section.name,
                      'hover:text-gray-800 ': currentMenu?.name != section.name
                    }"
                          class="text-gray-700 hover:text-gray-800 relative z-10 flex items-center justify-center text-sm font-medium transition-colors duration-200 ease-out" aria-expanded="false">
                    {{ section.name }}
                    <!-- Open: "bg-stone-600", Closed: "" -->
                    <span [ngClass]="{ 'bg-stone-600': currentMenu?.name == section.name }" class="absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out sm:mt-5 sm:translate-y-px sm:transform" aria-hidden="true"></span>
                  </button>
                </div>

                <div @opacity *ngIf='currentMenu' class="absolute inset-x-0 top-full">
                  <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                  <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true"></div>

                  <div class="relative bg-white">
                    <div class="mx-auto max-w-7xl px-8">
                      <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                        <div class="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                          <!-- category -->
                          <div *ngFor="let category of currentMenu.categories; let i=index" class="group relative aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden" [ngClass]="i == 0 ? 'col-span-2 aspect-w-2' : ''">
                            <img [src]="category.imgUrl" alt="{{category.altText}}" class="object-cover object-center group-hover:opacity-75">
                            <div class="flex flex-col justify-end">
                              <div class="bg-white bg-opacity-60 p-4 text-sm">
                                <a [href]="category.href" class="font-medium text-gray-900">
                                  <span class="absolute inset-0" aria-hidden="true"></span>
                                  {{ category.name }}
                                </a>
<!--                                <p aria-hidden="true" class="mt-0.5 text-gray-700 sm:mt-1 cursor-none">Shop now</p>-->
                              </div>
                            </div>
                          </div>

                        </div>
                        <ng-container *ngIf="currentMenu?.links">
                          <div class="grid grid-cols-3 gap-y-10 gap-x-8 text-sm text-gray-500">
                            <ng-container *ngFor="let quicklinks of currentMenu?.links;  let i=index">
                              <div class="space-y-10" *ngIf="i%2 == 1">
                                <div>
                                  <p id="women-shoes-heading1" class="font-medium text-gray-900 cursor-default">{{quicklinks.name}}</p>
                                  <ul role="list" aria-labelledby="women-shoes-heading" class="mt-4 space-y-4">
                                    <li *ngFor="let links of quicklinks.links" class="flex">
                                      <a [href]="links.href"
                                         (click)="
                                         this.gotToCategory(currentMenu!, quicklinks!, links!);
                                         nav.preventDefault($event);
                                         toggleOffCanvasMenu();
                                         selectCategory(currentMenu!.name)"
                                         class="hover:text-gray-800">{{ links.name }}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div *ngIf="i%2 == 0">
                                <p id="women-shoes-heading2" class="font-medium text-gray-900 cursor-default">{{quicklinks.name}}</p>
                                <ul role="list" aria-labelledby="women-shoes-heading" class="mt-4 space-y-4">
                                  <li *ngFor="let links of quicklinks.links" class="flex">
                                    <a [href]="links.href"
                                       (click)="
                                       this.gotToCategory(currentMenu!, quicklinks!, links!);
                                       nav.preventDefault($event);
                                       toggleOffCanvasMenu();
                                       selectCategory(currentMenu!.name)"
                                       class="hover:text-gray-800">{{ links.name }}</a>
                                  </li>
                                </ul>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <a *ngFor="let short of shortSection" (click)="nav.goToShop(short.href)" class="cursor-pointer flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">{{short.name}}</a>

            </div>
          </div>

          <div class="flex flex-1 items-center justify-end">
            <!-- Search -->
            <!--            <a href="#" class="ml-6 hidden p-2 text-gray-400 hover:text-gray-500 lg:block">-->
            <!--              <span class="sr-only">Search</span>-->
            <!--              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
            <!--                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />-->
            <!--              </svg>-->
            <!--            </a>-->

            <!-- Account -->
            <!--            <a href="#" class="p-2 text-gray-400 hover:text-gray-500 lg:ml-4">-->
            <!--              <span class="sr-only">Account</span>-->
            <!--              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
            <!--                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />-->
            <!--              </svg>-->
            <!--            </a>-->

            <!-- Cart -->
            <!--            <div class="ml-4 flow-root lg:ml-6">-->
            <!--              <a (click)="toggle_basket()" class="group -m-2 flex items-center p-2">-->
            <!--                <svg class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
            <!--                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />-->
            <!--                </svg>-->
            <!--                <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{numberItemInBag}}</span>-->
            <!--                <span class="sr-only">items in cart, view bag</span>-->
            <!--              </a>-->
            <!--            </div>-->

          </div>
        </div>
      </div>
    </nav>
  </header>
</div>


<!-- BreadCrumb -->
<div class="border-b border-gray-200 bg-white">
  <nav aria-label="Breadcrumb" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <ol role="list" class="flex items-center space-x-4 py-4">
      <li *ngFor="let bread of breadcrumb; let i = index">
        <div class="flex items-center">
          <a (click)="this.navigateToCategory(bread)" class="mr-4 text-sm font-medium text-gray-900 capitalize cursor-pointer">{{bread.name}}</a>
          <svg *ngIf="i+1 < breadcrumb.length" viewBox="0 0 6 20" aria-hidden="true" class="h-5 w-auto text-gray-300">
            <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor"/>
          </svg>
        </div>
      </li>
    </ol>
  </nav>
</div>
