import {Section} from "../interfaces/interfaces";
import { colors, sizes, fabric_propreties, fabric, user_type } from "../mockup_data/mockup_data";

export const clothingSections: Section[] = [
    {
        name: "Nouveautés",
        href: "Nouveautes",
        links: []
    },
    {
        name: "Dernière chance",
        href: "Derniere-chance",
        links: []
    },
    {
        name: "Alpinisme",
        href: "Alpinisme",
        links: [
            {
                name: 'Vêtements et chaussures',
                href: 'Vetements-et-chaussures',
                links: [
                    {name: "Chaussures", href: "Chaussures"},
                    {name: "Pantalons", href: "Pantalons"},
                    {name: "T-shirts et polaires", href: "T-shirts-et-polaires"},
                    {name: "Vestes et doudounes", href: "Vestes-et-doudounes"},
                    {name: "Gants et accessoires", href: "Gants-et-accessoires"},
                ],
            },

            {
                name: 'Sacs à dos',
                href: 'Sacs-a-dos',
                links: [
                    {name: "Tous les sacs", href: "Tout-les-sacs"},
                ],
            },
            {
                name: 'Matériel',
                href: 'Materiel',
                links: [
                    {name: "Baudriers et harnais", href: "Baudriers-et-harnais"},
                    {name: "Casques", href: "Casques"},
                    {name: "Matériel d'assurage", href: "Matériel-d'assurage"},
                    {name: "Piolets, crampons & broches à glace", href: "Piolets"},
                    {name: "Cordes d'escalade", href: "Cordes"}
                ],
            },
        ],
        categories: [
            { name: "Vetement", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sacs à dos", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Matériel", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Camping",
        href: "Camping",
        links: [
            {
                name: 'Tentes',
                href: 'Tentes',
                links: [
                    {name: "Camping", href: "Camping"},
                    {name: "Expédition", href: "Expédition"},
                    {name: "Accessoires", href: "Accessoires"},
                ],
            },
            {
                name: 'Sac de couchage',
                href: 'Sac-de-couchage',
                links: [
                    {name: "Adulte", href: "Adulte"},
                    {name: "Enfant", href: "Enfant"},
                    {name: "Bivouac", href: "Bivouac"},
                    {name: "Sacs à viande", href: "Sacs-à-viande"},
                ],
            },
            {
                name: 'Equipement',
                href: 'Equipement',
                links: [
                    {name: "Matelas de sol", href: "Matelas"},
                    {name: "Chaises", href: "Chaises"},
                    {name: "Tables", href: "Tables"},
                    {name: "Réchauds", href: "Réchauds"},
                    {name: "Vaisselle", href: "Vaisselle"},
                    {name: "Gourdes", href: "Gourdes"},
                    {name: "Lumières", href: "Lumières"},
                    {name: "Autres", href: "Autres"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Course à pied",
        href: "Course-a-pied",
        links: [
            {
                name: 'Chaussures',
                href: 'Chaussures',
                links: [
                    {name: "Toutes les chaussures", href: "chaussures"},
                ],
            },
            {

                name: 'Vêtements',
                href: 'Vetements',
                links: [
                    {name: "Vestes running", href: "vestes"},
                    {name: "Sweats running", href: "sweats"},
                    {name: "Pantalons running", href: "pantalons"},
                    {name: "T-shirts running", href: "tshirt"},
                    {name: "Shorts", href: "shorts"},
                    {name: "Sous-vêtements running", href: "sous-vetements"},
                ],
            },
            {
                name: 'Accessoires',
                href: 'Accessoires',
                links: [
                    {name: "Electronique", href: "electronique"},
                    {name: "Nutrition", href: "nutrition"},
                    {name: "Gourdes", href: "gourdes"},
                    {name: "Soins", href: "soins"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Escalade",
        href: "Escalade",

        links: [
            {
                name: 'Vêtements et chaussons',
                href: 'Vetements-et-chaussons',
                links: [
                    {name: "Chaussons d'escalade", href: "chaussons"},
                    {name: "Chaussures d'approche escalade", href: "chaussures-approche"},
                    {name: "Pantalons et shorts d'escalade", href: "bas"},
                    {name: "T-shirts et pulls d'escalade", href: "haut"},
                    {name: "Vestes et doudounes d'escalade", href: "vestes"},
                    {name: "Bonnets et accessoires", href: "bonnet-accessoires"}
                ],
            },
            {
                name: 'Matériel de sécurité',
                href: 'Materiel-de-securites',
                links: [
                    {name: "Baudriers, harnais d'escalade", href: "baudriers"},
                    {name: "Casques d'escalade", href: "escalade"},
                    {name: "Matériel d'assurage escalade", href: "materiel"},
                    {name: "Mousquetons et dégaines", href: "mousquetons"},
                    {name: "Crashpads", href: "crashpads"},
                    {name: "Cordes", href: "cordes"},
                ],
            },
            {
                name: 'Sacs',
                href: 'Sacs',
                links: [
                    {name: "Sacs à dos", href: "sac-dos"},
                    {name: "Sacs à corde", href: "sac-codre"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Randonnée",
        href: "Rando",
        links: [
            {
                name: 'Vêtements et chaussures',
                href: 'Vetements-et-chaussures',
                links: [
                    {name: "Chaussures", href: "chaussures"},
                    {name: "T-shirts", href: "top"},
                    {name: "Pantalons et Shorts", href: "pantalons"},
                    {name: "Veste imperméables", href: "impermeable"},
                    {name: "Softshells", href: "softshells"},
                    {name: "Doudounes", href: "doudounes"},
                    {name: "Pulls et polaires", href: "pull"},
                    {name: "Casquettes, chapeaux, bonnets", href: "couvres-chefs"},
                    {name: "Gants", href: "gants"},
                ],
            },
            {
                name: 'Sacs à dos',
                href: 'Sacs-a-dos',
                links: [
                    {name: "Tous les sacs", href: "saces"},
                ],
            },
            {
                name: 'Equipement',
                href: 'Equipement',
                links: [
                    {name: "Bâtons de marche", href: "baton"},
                    {name: "Lampes frontales", href: "lampe"},
                    {name: "Gourdes, poches à eau thermos", href: "gourde"},
                    {name: "GPS, alitimètre et boussoles", href: "accessoire"},
                    {name: "Lunettes de soleil", href: "lunettes"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Ski alpin",
        href: "Ski-alpin",
        links: [
            {
                name: 'Skis',
                href: 'Skis',
                links: [
                    {name: "Tous les skis", href: "skis"},
                ],
            },
            {
                name: 'Bâtons',
                href: 'Batons',
                links: [
                    {name: "Tous les bâtons", href: "batons"},
                ],
            },
            {
                name: 'Chaussures',
                href: 'Chaussures',
                links: [
                    {name: "Toutes les chaussures", href: "chaussures"},
                ],
            },
            {
                name: 'Vêtements',
                href: 'Vetements',
                links: [
                    {name: "Vestes", href: "veste"},
                    {name: "Pantalons", href: "pantalons"},
                    {name: "Chaussettes", href: "chaussettes"},
                    {name: "Polaires", href: "polaires"},
                    {name: "Sous-couches", href: "sous-couches"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Ski de randonnée",
        href: "Ski-de-rando",
        links: [
            {
                name: 'Skis',
                href: 'Skis',
                links: [
                    {name: "Tous les skis", href: "skis"},
                ],
            },
            {
                name: 'Fixations',
                href: 'Fixations',
                links: [
                    {name: "Toutes les fixations", href: "fixations"},
                ],
            },
            {
                name: 'Bâtons',
                href: 'Batons',
                links: [
                    {name: "Tous les bâtons", href: "batons"},
                ],
            },
            {
                name: 'Chaussures',
                href: 'Chaussures',
                links: [
                    {name: "Toutes les chaussures", href: "chaussures"},
                ],
            },
            {
                name: 'Vêtements',
                href: 'Vetements',
                links: [
                    {name: "Vestes", href: "vestes"},
                    {name: "Pantalons", href: "pantalons"},
                    {name: "Chaussettes", href: "chaussette"},
                    {name: "Polaires", href: "polaires"},
                    {name: "Sous-couches", href: "sous-couches"},
                ],
            },
            {
                name: 'Matériel de sécurité',
                href: 'Materiel-de-sécurité',
                links: [
                    {name: "DVA", href: "dva"},
                    {name: "Pelles", href: "pelles"},
                    {name: "Sondes", href: "sondes"},
                    {name: "Airbag", href: "airbag"},
                ],
            },
            {
                name: 'Sacs à dos',
                href: 'Sacs-a-dos',
                links: [
                    {name: "Sacs à dos", href: "sac"},
                    {name: "Sacs Airbag", href: "airbag"},
                ],
            },
        ],
        categories: [
            { name: "Tentes", imgUrl:"https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg", altText:"Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.", href: "/shop" },
            { name: "Sac de couchage", imgUrl:"https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg", altText:"Drawstring top with elastic loop closure and textured interior padding.", href: "/shop" },
            { name: "Equipement", imgUrl:"https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg", altText:"Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.", href: "/shop" }
        ],
    },
    {
        name: "Magasins",
        href: "shop",
        links: []
    },
]

export const shortSection = [
    {name: '', href: ''},
    // {name: 'Nouveautés', href: ''},
    // {name: 'Dernière Chance', href: ''},
]


export const categories = [
    { name: 'Couleurs', values: colors, id: 'color'},
    { name: 'Tailles', values: sizes, id: 'size' },
    { name: 'Propriétés', values: fabric_propreties, id: 'proprety' },
    { name: 'Matières', values: fabric, id: 'fabric' },
    { name: 'Utilisateurs', values: user_type, id: 'user' }
  ]