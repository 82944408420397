import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NavigationService } from "../../../shared/services/navigation.service";
import { CartService } from "../../../shared/services/cart.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Color, Product, Size } from "../../../../../../fe-global-components/interfaces/interfaces";
import { colors, product, sizes } from "../../../../../../fe-global-components/mockup_data/mockup_data";
import { ProductsService } from 'src/app/shared/services/products.service';



@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  animations: []
})
export class QuickViewComponent implements OnInit {
  public article_form: FormGroup = this.fb.group({
    size: [null, Validators.required],
    color: [null, Validators.required],
  })

  colors: Color[] = colors;
  sizes: Size[] = sizes;

  currentColor: Color | null = null;
  currentSize: Size | null = null;

  constructor(
    private fb: FormBuilder,
    public nav: NavigationService,
    protected ps: ProductsService,
    public dialogRef: MatDialogRef<QuickViewComponent>,
    protected cartService: CartService,
    @Optional() @Inject(MAT_DIALOG_DATA) public product: Product) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    if (!this.product) {
      this.product = product
    }

    this.ps.initProduct(this.product)
    this.ps.currentProduct.subscribe(p => this.product = p)

    this.ps._currentColors.subscribe(p => this.colors = p)
    this.ps._currentSize.subscribe(p => this.sizes = p)
    this.ps.curPickedColor.subscribe(p => this.currentColor = p)
    this.ps.curPickedSize.subscribe(p => this.currentSize = p)

    this.initForm()
  }

  goToArticle() {
    this.onNoClick();
    this.nav.goToArticle(this.product.id)
  }

  initForm() {
    this.article_form = this.fb.group({
      size: [this.colors[0].name, Validators.required],
      color: [this.sizes[1].name, Validators.required],
    })
  }

}
