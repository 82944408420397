import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { Link, Product, QuickLinks, Section } from "../../../../../fe-global-components/interfaces/interfaces";
import { clothingSections } from "../../../../../fe-global-components/constants/menu-shopping-links";
import { BffApiService } from "../api/bff-api.service";
import { NavigationService } from "./navigation.service";
import { categories } from '../../../../../fe-global-components/constants/menu-shopping-links';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root'
})
export class ShopMenuService {
  /* Liste des flitres qui vient sur le menu latéral 
  est composé comme suis:
   [
      [liste de lien à afficher],
      "titre du menu a afficher"
   ]
  exemple: 
    [
      [ 
        { name: "Chaussures", href: "chaussures" },
        ...
        { name: "Gants", href: "gants" }
      ],
      "Vêtements et chaussures"
    ]
*/
  categories: any[] = []
  private categoriesSource = new BehaviorSubject(this.categories);
  currentCategories = this.categoriesSource.asObservable();

  /**
   * pour l'instant vide: devrai contenir les couleurs, taille d'article etc.
   */
  params: { [key: string]: string[] } = {};
  private filtersSource = new BehaviorSubject(this.params);
  currentFilters = this.filtersSource.asObservable();

  /**
   * pour l'instant vide: devrai contenir l'ordre : prx le plus chere moin cher first etc.
   */
  sort: { [key: string]: string[] } = {};
  private sortSource = new BehaviorSubject(this.sort);
  currentSort = this.sortSource.asObservable();

  /**
 * contient le detail du breadcrumb
 */
  breadcrumb: any[] = []
  private breadcrumbSource: BehaviorSubject<any[]> = new BehaviorSubject(this.breadcrumb);
  currentBreadcrumb: Observable<any[]> = this.breadcrumbSource.asObservable();

  /**
 * contient les articles recuperé du back
 */
  products: Product[] = []
  private productsSource: BehaviorSubject<Product[]> = new BehaviorSubject(this.products);
  currentProducts: Observable<Product[]> = this.productsSource.asObservable();

  links: any[] = []
  main: any;
  cat: any;
  link: any;

  constructor(
    private router: Router,
    public nav: NavigationService,
    private bff: BffApiService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.includes('shop')) {
        this.extractURL(event.url)
        // this.requestShopProducts()
      }
    })

    this.requestShopProducts()

  }



  extractURL(url: string): void {
    // Split the URL by '?' if it exists
    const [baseUrl, queryString] = url.split('?');
    // Handle case where '?' is not present
    const links = baseUrl ? baseUrl.split('/').filter(part => part.length > 0) : [];
    // Handle case where queryString might be undefined
    const params = queryString ? queryString.split('&').filter(param => param.length > 0) : [];
    this.initParams(params);


    //TODO make the categories + title page + breadcrumb
    this.categoriesSource.next(this.initCategories(links))
    this.breadcrumbSource.next(this.initBreadcrumb(links))
  }


  requestShopProducts() {
    this.bff.getProducts(this.getParams()).subscribe((res: any) => this.productsSource.next(res.data))
  }


  updateLink(link: any): void { //TODO
    // update left categories and title if needed
    var values = this.searchInCategories(this.breadcrumbSource.value, link)
    this.categoriesSource.next([values[1], values[2]])

    // update breadcrumb 
    let breadcrumb = Array.isArray(values[0]) ? values[0] : []
    this.breadcrumbSource.next(breadcrumb)

    // update url 
    let urls = Array.isArray(values[3]) ? [...values[3]] : [];
    urls.unshift('shop');
    urls = Array.isArray(urls) ? urls : [];
    this.router.navigate(urls);
  }

  navigateToBreadCrumb(bread: any) {
    const index = this.breadcrumbSource.value.findIndex(obj => obj.href === bread.href);
    if (index !== -1) {
      // Update the breadcrumb : Cut the array to keep only the part up to and including the target object
      this.breadcrumbSource.next(this.breadcrumbSource.value.slice(0, index + 1))
      // update the categorie on the left navigation panel : 
      const categories = bread.quickLinks || bread.links || false;
      if (!categories) return;
      this.categoriesSource.next([categories, bread.name]);
      // update the url of the navigation
      const currentURL = this.router.url.split('/').filter(Boolean); // Removes empty elements (e.g., leading slash)
      const linkRoad = currentURL.slice(0, currentURL.indexOf(bread.href) + 1); // Collects parts until 'bread.href'
      this.router.navigate(linkRoad);
    } else {
      console.log('Target object not found in the array');
    }
  }

  changeLink(links: any[]): void {
    let linkRoad: string[] = []
    links.forEach(e => {
      linkRoad.push(e.href)
    })
    linkRoad.unshift("/shop");
    this.router.navigate(linkRoad);
    this.links = links
    this.breadcrumbSource.next(this.links)
    this.categoriesSource.next(this.calcCategories(links))
  }


  private calcCategories(links: any[]) {
    if (links.length < 1) {
      return clothingSections
    }
    return this.findCategories(links)
  }


  addParam(category: string, value: any) {
    // Check first if category exist in our categories range
    if (!categories.some(cat => cat.id === category)) {
      return
    }
    // Check if the category exists, if not, create it
    if (!this.params[category]) {
      this.params[category] = [];
    }
    this.params[category].push(value)
    this.filtersSource.next(this.params)
  }


  removeParam(category: string, value: any) {
    const index = this.params[category].indexOf('value');
    this.params[category].splice(index, 1);
    this.filtersSource.next(this.params)
  }


  initParams(parms_condensed: string[]) {
    /**
     * this is made to store the parameters of the url 
     * in order to call the backend article according to the url requested.
     */
    parms_condensed.forEach(param => {
      const [key, value] = param.split('=');
      if (key && value) {
        this.addParam(key, value);
      }
    });
  }


  private getParams() {
    let params_String = ''

    if (Object.keys(this.params).length > 0) {
      params_String = params_String + ""
      for (const [key, values] of Object.entries(this.params)) {
        let val
        switch (key) {
          case 'Couleurs':
            // @ts-ignore
            val = values.map(value => value['name']).join(',');
            params_String += `[variants][color][name][$eq][${val}]`;
            break;
          case 'Tailles':
            // Add the logic for 'Tailles' category
            // @ts-ignore
            val = values.map(value => value['name']).join(',');
            params_String += `[variants][size][name][$eq][${val}]`;
            break;
          case 'Propriétés':
            // @ts-ignore
            val = values.map(value => value['name']).join(',');
            params_String += `[proprety][name][$eq][${val}]`;
            break;
          case 'Matières':
            // @ts-ignore
            val = values.map(value => value['name']).join(',');
            params_String += `[fabric][name][$eq][${val}]`;
            break;
          default:
            break;
        }
      }
    }
    return params_String;
  }


  initBreadcrumb(links: string[]) {
    let breadcumbs = []
    let categories = clothingSections;

    for (const link of links) {
      if (link == 'shop') { continue }
      const category = categories.find(cat => cat.href === link);

      if (category) {
        breadcumbs.push(category)
        if ((category as any).links) {
          categories = (category as any).links;
        }
        else if ((category as any).quickLinks) {
          categories = (category as any).quickLinks;
        }
        else {
          return breadcumbs;
        }
      }
    }
    return breadcumbs
  }

  initCategories(links: string[]) {
    let categories = clothingSections;
    let title = 'Les catégories'

    for (const link of links) {
      if (link == 'shop') { continue }

      const category = categories.find(cat => cat.href === link);

      if (category) {
        if ((category as any).links) {
          categories = (category as any).links;
          title = category.name
        } else if ((category as any).quickLinks) {
          categories = (category as any).quickLinks;
          title = category.name
        } else {
          return [categories, title];
        }
      }
    }
    return [categories, title]; // Return the final categories even if no matches are found
  }


  findCategories(links: any[]): any {
    let categories = clothingSections;
    let title = 'Les catégories'

    for (const link of links) {
      const category = categories.find(cat => cat.href === link.href);

      if (category) {
        if ((category as any).links) {
          categories = (category as any).links;
          title = category.name
        } else if ((category as any).quickLinks) {
          categories = (category as any).quickLinks;
          title = category.name
        } else {
          return [categories, title];
        }
      }
    }
    return [categories, title]; // Return the final categories even if no matches are found
  }


  searchInCategories(links: any[], newCategory: { name: string, href: string }) {
    let categories = clothingSections;
    let title = 'Les catégories'

    let breadcrumb = []
    let urlPart = []

    for (const link of links) {
      const Newcategory = categories.find(cat => cat.href === newCategory.href);
      const category = categories.find(cat => cat.href === link.href);

      if (Newcategory && ((Newcategory as any).links)) {
        categories = (Newcategory as any).links;
        title = Newcategory.name
        breadcrumb.push(Newcategory)
        urlPart.push(Newcategory.href)
      }
      else if (Newcategory && ((Newcategory as any).quickLinks)) {
        categories = (Newcategory as any).quickLinks;
        title = Newcategory.name
        breadcrumb.push(Newcategory)
        urlPart.push(Newcategory.href)
      }
      else if (category && ((category as any).links)) {
        categories = (category as any).links;
        title = category.name
        breadcrumb.push(category)
        urlPart.push(category.href)
      }
      else if (category && category.links) {
        categories = (category as any).quickLinks;
        title = category.name
        breadcrumb.push(category)
        urlPart.push(category.href)
      }
    }

    const Newcategory = categories.find(cat => cat.href === newCategory.href);
    if (Newcategory && ((Newcategory as any).links)) {
      categories = (Newcategory as any).links;
      title = Newcategory.name
      breadcrumb.push(Newcategory)
      urlPart.push(Newcategory.href)

    }
    else if (Newcategory && ((Newcategory as any).quickLinks)) {
      categories = (Newcategory as any).quickLinks;
      title = Newcategory.name
      breadcrumb.push(Newcategory)
      urlPart.push(Newcategory.href)
    }
    else if (Newcategory) {
      breadcrumb.push(Newcategory)
      urlPart.push(Newcategory.href)
    }

    return [breadcrumb, categories, title, urlPart];
  }







}
